import {
  Container, Center
} from '@mantine/core';
import React, { useEffect } from 'react';
import api from '../api';
import Organization from '../components/organization';
import ToolBar from '../components/organization_tool_bar';
import queryString from 'query-string';

const Organizations = () => {
  const [organizations, setOrganizations] = React.useState([]);
  const [query, setQuery] = React.useState({});

  const handleSearch = (search) => {
    const newQuery = { ...query, search, disabled: false };
    const t = queryString.stringify(newQuery, {arrayFormat: 'bracket'});
    setQuery(newQuery);
    api().get(`/organizations?${t}`)
      .then(({ data }) => {
        setOrganizations(data?.data);
      });
  }

  const handleServiceTagsFilter = (tags) => {
    const newQuery = { ...query, badges: tags };
    const t = queryString.stringify(newQuery, {arrayFormat: 'bracket'});
    setQuery(newQuery);
    api().get(`/organizations?${t}`)
      .then(({ data }) => {
        setOrganizations(data?.data);
      });
  }

  const handleFollow = (id) => {
    const isFollowed = organizations.find(o => o.id === id).followed;
    if (isFollowed) {
      api().post(`/organizations/${id}/unfollow`)
        .then((r) => {
          if (r.data.success) {
            setOrganizations(organizations.map(o => {
              if (o.id === id) {
                return { ...o, followed: false };
              }
              return o;
            }));
          }
        });
    } else {
      api().post(`/organizations/${id}/follow`)
        .then((r) => {
          console.log(r)
          if (r.data.success) {
            setOrganizations(organizations.map(o => {
              if (o.id === id) {
                return { ...o, followed: true };
              }
              return o;
            }));
          }
        });
    }
  }

  useEffect(() => {
    const fetchOrgs = async () => {
      return (await api().get('organizations?disabled=false&perPage=1000')).data.data;
    }
    fetchOrgs().then(setOrganizations);
  }, []);

  return <Container sx={(theme) => ({
    marginTop: theme.spacing.md,
  })}>

    <ToolBar
      handleSearch={handleSearch}
      handleServiceTagsFilter={handleServiceTagsFilter}
    />
    <Center style={{ width: '100%', height: 'auto' }}>
      <div>
        {organizations.map(organization => <Organization {...organization} handleFollow={handleFollow}/>) || <div>Loading...</div>}
      </div>
    </Center>
  </Container>
}



export default Organizations